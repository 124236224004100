import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const HalloForm = () => {
  return (
    <div className='hallo-container'>
      <form
        action='https://formspree.io/f/xayvpdrz'
        method='POST'
        className='form hallo-form'
      >
        <div className='form-row'>
          <h5>Zeg Hallo!</h5>
          <input
            type='text'
            name='namehallo'
            id='namehallo'
            required
            placeholder='naam'
          />
        </div>
        <div className='form-row'>
          <input
            type='text'
            name='emailhallo'
            id='emailhallo'
            required
            placeholder='email of telefoon'
          />
        </div>
        <div className='form-row'>
          <textarea
            name='messagehallo'
            id='messagehallo'
            placeholder='jouw berichtje'
          />
        </div>
        <div className='form-row'>
          <button type='submit' className='btn hipster block'>
            Verzend
          </button>
        </div>
      </form>
      <div className='hallo-img-container'>
        <StaticImage
          src='../images/home/tot_snel.png'
          alt='laurieusFit'
          loading='eager'
          className='hallo-img'
          objectFit='cover'
        ></StaticImage>
      </div>
    </div>
  )
}

export default HalloForm
