import React, { useState } from 'react'
import mailerLiteFetch from '../axios/global'
import FreebieResult from './FreebieResult'
import Modal from './Modal'

const FreebieForm = () => {
  const [email, setEmail] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [response, setResponse] = useState()
  const freebieGroup = '112528962090960644'

  const handleSubmit = async (e) => {
    e.preventDefault()
    handleData()
    setIsOpen(true)
    setEmail('')
    setResponse('')
  }

  const handleData = async () => {
    try {
      postData()
    } catch (error) {
      setResponse(error.response)
    }
  }

  const postData = async () => {
    try {
      const response = await mailerLiteFetch.post('api/subscribers', {
        email: email,
        groups: [freebieGroup],
        status: 'active',
        withCredentials: false,
      })
      setResponse(response)
    } catch (error) {
      setResponse(error.response)
    }
  }

  return (
    <div className='freebie-container'>
      {isOpen && (
        <Modal setIsOpen={setIsOpen}>
          <FreebieResult response={response} />
        </Modal>
      )}
      <form className='form freebie-form' onSubmit={handleSubmit}>
        <div className='form-row'>
          <h5>Grijp jouw gratis resource</h5>
          <h2>22 voordelen van krachttraining</h2>
          <p>
            Ik geloof niet dat er maar één reden is om met krachttraining te
            starten, naast het plezier en de uitdaging, kan dit jou misschien
            helpen een paar inzichten en motivatie te geven...
          </p>
        </div>
        <div className='form-row'>
          <input
            placeholder='email'
            type='email'
            name='email'
            id='email'
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            required
            style={{ width: 'auto', marginRight: '1rem' }}
          />
          <button type='submit' className='btn hipster'>
            Ontvang
          </button>
        </div>
      </form>
    </div>
  )
}

export default FreebieForm
