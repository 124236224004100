import React from 'react'
import Pdf from '../../static/resources/22_voordelen_van_krachttraining.pdf'

const FreebieResult = ({ response }) => {
  return (
    <>
      {response.status === 200 && (
        <>
          <h5>Welkom terug</h5>
          <p>Je bent ingeschreven om je freebie hier te ontvangen:</p>
          <a href={Pdf} target='_blank'>
            Download Pdf
          </a>
        </>
      )}
      {response.status === 201 && (
        <>
          <h5>Bedankt</h5>
          <p>Je bent ingeschreven om je freebie hier te ontvangen:</p>
          <a href={Pdf} target='_blank'>
            Download Pdf
          </a>
        </>
      )}
      {response.status > 201 && (
        <>
          <p>Er is iets fout gelopen. Stuur me een berichtje.</p>
          <p className='text-small'>{response.data.message}</p>
        </>
      )}
    </>
  )
}

export default FreebieResult
