import React from 'react';
import ArticlesList from './ArticlesList';
import TagsList from './TagsList';
import { graphql, useStaticQuery } from 'gatsby';

export const query = graphql`
  {
    allContentfulArticle(sort: { fields: createdWhen, order: DESC }) {
      nodes {
        featured
        createdWhen
        createdBy
        id
        order
        tags
        title
        image {
          gatsbyImageData
          title
        }
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
const AllArticles = () => {
  const data = useStaticQuery(query);
  const articles = data.allContentfulArticle.nodes;
  return (
    <section className='recipes-container'>
      <TagsList articles={articles} />
      <ArticlesList articles={articles} />
    </section>
  );
};

export default AllArticles;
