import React from 'react'

const PromoForm = () => {
  return (
    <form
      action='https://formspree.io/f/xayvpdrz'
      method='POST'
      className='form promo-form'
    >
      <div className='form-row'>
        <h5>Krachttraining</h5>
        <h2>Try-Out</h2>
        <p>
          Altijd al willen weten wat krachttraining voor jou kan betekenen? Laat
          hieronder je interesse weten en krijg jouw 3 eerste sessies gratis en
          vrijblijvend.
        </p>
      </div>
      <div className='form-row'>
        <input
          type='text'
          name='emailpromo'
          id='emailpromo'
          required
          placeholder='email of telefoon'
        />
      </div>
      <div className='form-row'>
        <button type='submit' className='btn hipster block'>
          Verzend
        </button>
      </div>
    </form>
  )
}

export default PromoForm
