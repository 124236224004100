import React from 'react'
import { Link } from 'gatsby'

const BlogAction = () => {
  return (
    <div className='blogAction-container'>
      <div className='blogAction-content'>
        <h5>Neem een kopje thee</h5>
        <h3>
          & bezoek <Link to='/blog'>mijn Blog</Link>
        </h3>
        In mijn blog ga ik op onderzoek naar onderwerpen over training, voeding
        en mindset om jou te ondersteunen om een gezond en tevreden leven te
        leiden.
      </div>
    </div>
  )
}

export default BlogAction
