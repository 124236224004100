import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import TabDescription from '../components/TabDescription'
import AllArticles from '../components/AllArticles'
import PromoForm from '../components/PromoForm'
import HalloForm from '../components/HalloForm'
import BlogAction from '../components/BlogAction'
// import StartupModal from '../components/StartupModal'
import SEO from '../components/SEO'
import myimage from '../images/logo_lf_big.jpg'
import '../axios/global'
import FreebieForm from '../components/FreebieForm'
// import SnowFlakes from '../components/SnowFlakes';

export const query = graphql`
  {
    contentfulTabDescription(tabname: { eq: "Home" }) {
      description {
        childMarkdownRemark {
          html
        }
      }
      tabname
      title
    }
  }
`

export default function Home() {
  const data = useStaticQuery(query)
  const mytab = data.contentfulTabDescription
  const [isOpenModal, setIsOpenModal] = useState(false)

  console.log('in home')
  useEffect(() => {
    console.log('use effect')
    setTimeout(() => setIsOpenModal(true), 3000)
  }, [])

  return (
    <>
      <Layout>
        {/* {isOpenModal && <StartupModal setIsOpen={setIsOpenModal} />} */}
        <SEO title='Home' />
        {/* <SnowFlakes /> */}
        <main className='page'>
          <header className='hero'>
            <div className='hero-img-container'>
              <img className='hero-img' src={myimage} alt='laurieusfit' />
            </div>
            <PromoForm />
          </header>
          <TabDescription {...mytab} />
          <BlogAction />
          <FreebieForm />
          <HalloForm />
          <AllArticles />
        </main>
      </Layout>
    </>
  )
}
